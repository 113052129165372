import { NavLink } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import buttonhome from './assets/buttonhome3.png';
import './game.css';
import Footer from './footer';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4'; // Import ReactGA

export default function Game(){

 // Track the pageview on component mount
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/game", title: "Gnats Game Page" });
    }, []);

    return(
        <div>
			<div>
                <div className="homebutton-game"><NavLink to='/'><Image src={buttonhome}></Image></NavLink></div>
                <div className="game-gnats">
                    <h1>Rise of the Gnats</h1>
                    <h1>A Thrilling Battle Ship Game</h1>
                </div>
				<div className='game_gnatsgif' />
            </div>
				<div className="box-main-game">
                <div> 
						<h1 className="subheading-game">
                        Step into the World of Tiny Titans!
						</h1>
						<p className="text-small-game">
                        Welcome to "Rise of the Gnats", a fast-paced and action-packed game where the tiniest creatures take on the mightiest challenges! Prepare to test your reflexes, strategy, and nerves in this epic battle of skill and survival.
						</p>
                        <h1 className="subheading-game">
                        Game Architecture & Technology
						</h1>
						<p className="text-small-game">
                        Rise of the Gnats is a browser-based, fast-paced action game built with JavaScript, leveraging modern WebGL and HTML5 Canvas for smooth rendering. The game runs natively in any modern browser without requiring additional plugins.
                        Why It's Fast?
                        Optimised JavaScript Engine: Utilises efficient game loops, requestAnimationFrame, and event-driven programming for seamless performance.
                        WebGL & Canvas API: Enables hardware-accelerated rendering, reducing CPU load and maximising FPS.
                        Minimal Latency Input Handling: Uses low-latency input event listeners for responsive gameplay.
                        Asset Management & Lazy Loading: Dynamically loads assets, reducing initial load times and improving performance.
						</p>
                        <p className='text-small-game'>
                        Core Features
                        🔹 Dynamic AI Mechanics – Adaptive enemy behaviour and real-time difficulty scaling.
                        🔹 Physics-Based Movements – Smooth, realistic motion leveraging optimised physics calculations.
                        🔹 Multiplayer Potential – Future scope for WebSocket or WebRTC-based multiplayer interactions.
                        🔹 Progressive Web App (PWA) Ready – Can be installed as a lightweight web app for offline gameplay.

                        Stay tuned for upcoming updates, power-ups, and new levels! 🚀
                        </p>
                        <h1 className="subheading-game">
                        Gameplay Highlights
						</h1>
						<p className="text-small-game">
                        Dynamic Challenges: Navigate through ever-changing obstacles and environments to prove your mastery.
                        Custom Strategies: Use quick thinking and sharp reflexes to guide your gnats and outsmart the enemy.
                        Unique Power-Ups: Unlock special boosts to turn the tide of battle in your favour.
                        High-Intensity Battles: Engage in exciting aerial combat with immersive visuals and sound effects.
                        Test Your Limits: Achieve high scores and challenge friends to beat your record!
                        </p>
                        <h1 className="subheading-game">
                        How to Play
						</h1>
						<p className="text-small-game">
                        Start the Game: Click the "Play Now" button to launch the game in a new window.
                        Control Your Gnats: Use precise movements and timing to navigate your gnats through the battlefield.
                        Achieve Victory: Avoid obstacles, defeat enemies, and climb the leaderboard to become the ultimate champion!
                        </p>
                    
                        <div className="button-container">
                            <a href="https://game.gnats.cc" target="_blank" rel="noopener noreferrer">
                                <button className="play-button">PLAY NOW</button>
                            </a>
                        </div>
					</div>
				</div>	
			<div className='empty-game' /> 
            <Footer></Footer>
        </div>
    )
}
